.breadcrumb-story-container .mr-10 {
  margin-right: 0.625rem;
}
.breadcrumb-story-container .font-14 {
  font-size: 0.875rem;
}
.breadcrumb-story-container .font-13 {
  font-size: 0.8125rem;
  }
.breadcrumb-story-container .font-16 {
  font-size: 1rem;
}
.text-underline {
  color: #d04a02;
}

.breadcrumbsMenu {
  border: none;
  padding-bottom: 15px;
}

.breadcrumbLink {
  color: #000;
  font-weight: bold;
}
