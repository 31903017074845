.a-select-list-out {
  min-width: 250px;
}

.a-header-wrapper {
  height: auto;
  min-height: 3.125rem;
}

.a-bread-item span a {
  color: #d04a02;
}

.a-actions-container .a-account-info .a-avatar {
  width: 1.8rem;
  height: 1.8rem;
}

.a-account-options.a-tooltip {
  z-index: 9991;
}

.a-loading.a-loading-36 {
  height: 1.8rem;
  width: 1.8rem;
}

.a-brand-item-container.active .a-copy-icon {
  color: #d04a02;
}

.a-brand-item-container.active .a-h6 {
  color: #d04a02;
}

.a-brand-item-container .a-h6:hover {
  color: #d04a02;
}
