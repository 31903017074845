.loginContainer {
  max-width: 500px;
  margin: 0 auto; }
  .loginContainer h5 {
    text-align: center;
    margin-bottom: 20px; }

.loginButton {
  margin: 15px auto; }

.loginPanel {
  max-width: 460px;
  box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.16);
  padding-top: 20px;
  padding-left: 41px;
  padding-right: 41px;
  padding-bottom: 42px;
  background-color: #fff;
  margin: 5% auto 0 auto; }

.loginPanel button {
  display: block;
  margin: 0 auto; }
