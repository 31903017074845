.icon{
  margin:10px;
}
.metricsLi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  border-bottom: 0.0625rem solid #eee;
}
.metricsLi span { width: 10%; }
.metricsFont {
  color: #333;
  font-size: 1rem;
}

.newRulesetButtonContainer {
  margin-top: 2em;
  text-align: right;
}

.heading {
  margin-bottom: 1em;
}

.rulesetPanel {
  margin-bottom: 1em;
}

.buttonGroups {
  text-align: right;
}

.manageRulesetButton {
  margin-left: 1em;
}
