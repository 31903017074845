.newRuleButton {
  float: right;
  margin-bottom: 2em;
  margin-left: 10px;
}

.conditionsTable :global(.rt-noData) {
  min-height: 0
}

.conditionsTable :global(.rt-thead .rt-th) {
  font-size: 0.9em;
  font-weight: 450;
}

.conditionsTable :global(.rt-thead.-header) {
  background-color: transparent;
}

:global(.ReactTable) .criterionTableBody {
  overflow: visible;
}

.criterionTableDropdownCell {
  overflow: visible !important;
  width: 100%;
}

.criterionTableDropdownCell :global(.rule-criterion-select__control) {
  line-height: normal;
  font-size: 12px;
  border-radius: 0;
  border: .0625rem solid #dedede;
  min-height: 25px;
}

.criterionTableDropdownCell :global(.rule-criterion-select__indicator) {
  padding: 0;
}

.criterionTableDropdownCell :global(.a-dropdown .select-toggle) {
  height: 28px;
}

.criterionTableDropdownCell :global(.a-dropdown .select-toggle .select-toggle-icon.arrow-icon) {
  top: 9px;
  font-size: 0.5rem;
}

.criterionTableDropdownCell :global(.a-dropdown .select-toggle .select-toggle-text-wrapper) {
  font-size: 12px;
}

.criterionTableSmallCell {
  max-width: 50px;
}

.criterionTableGroupCell {
  max-width: 100px;
}

.criterionTableGroupCell input {
  background-color: white;
}

.criterionTableBody .criterionTableRow {
  flex: none;
}

.criterionTableAndOrCell {
  max-width: 100px;
}

@media (min-width: 992px) {
  .ruleRightPane {
    border-left: 1px solid #dedede;
    margin-top: 10px;;
  }
}

@media (max-width: 992px) {
  .ruleRightPane {
    margin-top: 0.5rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    border-top: 1px solid #dedede;
  }
}

.actionLabel {
  font-size: 0.9em;
  font-weight: 450;
  display: inline-block;
  margin-right: 1em;
}

.actionForm {
  display: inline-block;
  width: calc(100% - 50px);
  max-width: 200px;
}

.actionForm :global(.a-select-wrapper) {
  width: calc(100% - 50px);
  display: inline-block;
}

.actionForm :global(.a-dropdown .select-toggle) {
  height: 1.5rem;
  line-height: 1.5rem;
}

.actionForm :global(.a-dropdown .select-toggle .select-toggle-text-wrapper) {
  font-size: 12px;
}
.actionForm :global(.a-dropdown .select-toggle .select-toggle-icon.arrow-icon) {
  top: 6px;
  font-size: 0.5rem;
}

.actionFormButton {
  display: inline-block;
}

.reviewNeededAction {
  margin-top: 10px;
}

.reviewNeededDeleteButton {
  float: right;
}

.reviewNeededDeleteButton :global(.appkiticon) {
  margin-right: 0;
  font-size: 0.75rem;
}

.rulesetFieldTable {
  padding: 0;
}
