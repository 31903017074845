@charset "UTF-8";
.nav-template {
  height: 100%; }
  .nav-template .navigation {
    position: static; }
    .nav-template .navigation .top-bar {
      min-height: 3.75rem;
      height: auto; }
      .nav-template .navigation .top-bar .sidebar-ctrl-box .hamberger-btn.reversal-action .appkiticon {
        animation: reversal 0.5s linear;
        animation-fill-mode: forwards; }

@keyframes reversal {
  from {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    /* Firefox */
    -webkit-transform: rotate(0deg);
    /* Safari 和 Chrome */ }
  to {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    /* Firefox */
    -webkit-transform: rotate(180deg);
    /* Safari 和 Chrome */ } }
      .nav-template .navigation .top-bar .global-search .search {
        width: 100%;
        margin: .5625rem .625rem .5625rem 0; }
        .nav-template .navigation .top-bar .global-search .search .input-default {
          background: #f5f5f5;
          border: none; }
        .nav-template .navigation .top-bar .global-search .search input {
          background: #f5f5f5; }
        .nav-template .navigation .top-bar .global-search .search .input .input-block .input-prefix {
          padding-left: 0.625rem; }
      .nav-template .navigation .top-bar .notification-box {
        position: relative; }
        .nav-template .navigation .top-bar .notification-box .new-notification-flag {
          position: absolute;
          width: 0.3125rem;
          height: 0.3125rem;
          border-radius: 0.15625rem;
          background-color: #d04a02;
          top: 0.6875rem;
          right: 1.25rem; }
      @media screen and (max-width: 35.9375rem) {
        .nav-template .navigation .top-bar .navbar-fold-xs.vis {
          overflow: visible; } }
      .nav-template .navigation .top-bar .tooltip-ref.tooltip-ref-relative {
        position: static; }
        .nav-template .navigation .top-bar .tooltip-ref.tooltip-ref-relative .tooltip.bottom {
          padding: 0;
          margin-top: 0; }
        .nav-template .navigation .top-bar .tooltip-ref.tooltip-ref-relative .tooltip.dark-panel .popover-content {
          box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(49, 57, 63, 0.2), 0 0 0 0.0625rem rgba(49, 57, 63, 0.05); }
  /* .nav-template.template-theme-two {
    min-width: 63.625rem; } */
    .nav-template.template-theme-two .content {
      overflow: hidden;
      height: 100%;
      background-color: #31393f; }
      .nav-template.template-theme-two .content .left-nav {
        float: left;
        height: 100%;
        background-color: #31393f;
        width: 0;
        overflow-x: hidden;
        overflow-y: auto; }
        .nav-template.template-theme-two .content .left-nav li {
          cursor: pointer; }
        .nav-template.template-theme-two .content .left-nav .left-nav-item-list .user-config {
          position: relative;
          padding: 2.25rem 2rem 1.875rem; }
          .nav-template.template-theme-two .content .left-nav .left-nav-item-list .user-config .user-name {
            margin: 0 auto;
            width: 4rem;
            height: 4rem;
            text-align: center;
            vertical-align: middle;
            color: #ffffff;
            background-color: #d04a02;
            border-radius: 100%;
            font-size: 1.5rem;
            display: block;
            line-height: 4rem;
            cursor: pointer; }
          .nav-template.template-theme-two .content .left-nav .left-nav-item-list .user-config .config-btn {
            position: absolute;
            top: 2.25rem;
            right: 2rem; }
            .nav-template.template-theme-two .content .left-nav .left-nav-item-list .user-config .config-btn .appkiticon .appkit_icon_fill_color {
              fill: #999999; }
            .nav-template.template-theme-two .content .left-nav .left-nav-item-list .user-config .config-btn .appkiticon .appkit_icon_stroke_color {
              stroke: #999999; }
        .nav-template.template-theme-two .content .left-nav .left-nav-item-list .setting-item {
          display: none;
          padding: 1.3125rem 2rem;
          color: #ffffff;
          justify-content: flex-start;
          align-items: center; }
          .nav-template.template-theme-two .content .left-nav .left-nav-item-list .setting-item .appkiticon .appkit_icon_fill_color {
            fill: #ffffff; }
          .nav-template.template-theme-two .content .left-nav .left-nav-item-list .setting-item .appkiticon .appkit_icon_stroke_color {
            stroke: #ffffff; }
          .nav-template.template-theme-two .content .left-nav .left-nav-item-list .setting-item .logout-svg {
            margin-right: 0.625rem; }
          .nav-template.template-theme-two .content .left-nav .left-nav-item-list .setting-item.show-logout {
            display: flex; }
        .nav-template.template-theme-two .content .left-nav .menu-wrapper {
          width: unset; }
        .nav-template.template-theme-two .content .left-nav.openLeftNav {
          animation: openLeft 0.5s ease;
          animation-fill-mode: forwards; }

@keyframes openLeft {
  from {
    width: 0; }
  to {
    width: 15rem; } }
        .nav-template.template-theme-two .content .left-nav.closeLeftNav {
          animation: closeLeft 0.5s ease;
          animation-fill-mode: forwards; }

@keyframes closeLeft {
  from {
    width: 15rem; }
  to {
    width: 0; } }
      .nav-template.template-theme-two .content .right-content {
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #f2f2f2; }
        .nav-template.template-theme-two .content .right-content .example-item {
          height: 100%;
          width: 100%;
          overflow: auto;
          padding: 1.563rem;
          flex: 1 1 auto; }
        .nav-template.template-theme-two .content .right-content .example-item-content {
          height: 100%;
          background-color: #ffffff; }
        .nav-template.template-theme-two .content .right-content .footer {
          width: 100%;
          flex: 0 0 auto;
          background-color: #31393f;
          line-height: 1.5rem;
          padding-top: .5rem;
          padding-bottom: .5rem; }
          @media screen and (max-width: 61.9375rem) {
            .nav-template.template-theme-two .content .right-content .footer .nav-footer {
              margin-top: 0; } }
      .nav-template.template-theme-two .content.shrink-right {
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation: shrink-action 0.5s ease; }
      .nav-template.template-theme-two .content.pop-right {
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation: pop-action 0.5s ease; }

@keyframes shrink-action {
  from {
    padding-top: 0; }
  to {
    padding-top: 1.75rem; } }

@keyframes pop-action {
  from {
    padding-top: 1.75rem; }
  to {
    padding-top: 0; } }
      .nav-template.template-theme-two .content.pop-right {
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation: pop-action 0.5s ease; }

.footer {
  padding: 1rem;
}

.a-breadcrumb-active {
  color: #fff;
  background-color: #d04a02;
  padding: 3px 5px;
  border-radius: 2px;
  font-weight: 500;
  cursor: default;
}

/* scenario: login */
.scenario-login .a-header-wrapper {
  display: none;
}

.appkit-modal {
  z-index: 999999;
}
