.menuLink {
  color: #ffffff;
}

.subheaderContainer {
  /* background-color: #ffffff; */
  margin-top: 20px;
  width: 100%;
  padding-left: 80px;
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 0;
}

.header {
  z-index: 9990;
}

.headerLink {
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  padding-left: 10px;
}

.headerDivider {
  vertical-align: middle;
}

.logo {
  cursor: pointer;
}
